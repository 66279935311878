import { Injectable } from '@angular/core'
import isoCodeList from 'src/assets/i18n/isocodelist.json'

interface Language {
  isoCode: string
  languageName: string
  nativeLanguageName: string
}

export interface IsoLangs {
  [key: string]: {
    name: string
    nativeName: string
  }
}

export type langKey = keyof IsoLangs
const isoLangs = isoCodeList.isoLangs as IsoLangs

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public getSupportedLanguages(): Language[] {
    const languages: Language[] = []

    for (const locale of Object.keys(isoLangs)) {
      languages.push({
        isoCode: locale,
        languageName: isoLangs[locale].name,
        nativeLanguageName: isoLangs[locale].nativeName,
      })
    }

    return languages
  }
}
