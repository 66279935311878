// angular
import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
// app
import { KeycloakService } from 'keycloak-angular'
import { AppService } from '../../../../app.service'
import { ProficloudService } from '../../services/proficloud.service'

@Component({
  selector: 'app-authentication-screen',
  templateUrl: './authentication-screen.component.html',
})
export class AuthenticationScreenComponent {
  private readonly AUTH_STORAGE_ITEMS: string[] = [
    'access_token',
    'access_token_stored_at',
    'expires_at',
    'granted_scopes',
    'id_token',
    'id_token_claims_obj',
    'id_token_expires_at',
    'id_token_stored_at',
    'nonce',
    'PKCE_verifier',
    'refresh_token',
    'session_state',
  ]

  local = false

  constructor(
    public proficloud: ProficloudService,
    private route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private auth: KeycloakService
  ) {
    if (window.location.hostname === 'localhost') {
      // stay here
      this.local = true
    } else {
      // redirect to wordpress landing
      this.login()
    }
  }

  login() {
    // Initialize code flow. The authorization data are stored in the browsers session store
    // The oauth lib will load the auth-callback component after redirecting to the identity provider
    this.auth.login({
      redirectUri: window.location.origin + '/auth-callback',
    })
  }
}
