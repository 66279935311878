import { Injectable } from '@angular/core'
import { HttpBaseService } from '@services/http-base.service'
import { Subject } from 'rxjs'
import { PcStatusOverlayService } from 'src/app/modules/shared/services/pc-status-overlay/pc-status-overlay.service'
import { ClassDoc } from '../../../../shared/services/tracing/tracing.interfaces'
import { TracingService } from '../../../../shared/services/tracing/tracing.service'
import {
  AvailableFirmwareResponse,
  DeviceInfo,
  FirmwareResult,
  FirmwareStatus,
  FirmwareStatusCodes,
  FirmwareUpdate,
  FirmwareUpdateIcon,
  FirmwareUpdateResults,
  FirmwareUpdateStageLabel,
  FirmwareUpdateStatus,
  FirmwareUpdateStatuses,
  FirmwareV2,
} from '../../../services/proficloud.interfaces'
import { ProficloudService } from '../../../services/proficloud.service'
import { DeviceStore } from '../stores/device.store'
import { DeviceService } from './device.service'

@Injectable({
  providedIn: 'root',
})
export class FirmwareService {
  classDoc: ClassDoc = {
    name: 'FirmwareService',
  }

  constructor(
    private deviceStore: DeviceStore,
    private deviceService: DeviceService,
    public proficloud: ProficloudService,
    private statusOverlay: PcStatusOverlayService,
    public tracing: TracingService,
    private httpBase: HttpBaseService
  ) {
    this.tracing.registerInstance(this)
  }

  firmwareUpdate$ = new Subject<DeviceInfo | false>()
  cancelFirmwareUpdate$ = new Subject<FirmwareUpdate | false>()

  firmwareUpdateIcons: Record<FirmwareStatusCodes, FirmwareUpdateIcon> = {
    // not started
    '-1': false,
    // compatibility
    0: 'spinner',
    1: 'check',
    2: 'error',
    // download
    3: 'spinner',
    4: 'check',
    5: 'error',
    // download check
    6: 'spinner',
    7: 'check',
    8: 'error',
    // installation
    9: 'spinner',
    10: 'check',
    11: 'error',
    // misc errors
    12: 'error',
    13: 'error',
  }

  firmwareUpdateStages: FirmwareUpdateStageLabel[] = [
    {
      name: 'Step 01 - Checking Permissions',
      key: 'compatibility',
    },
    {
      name: 'Step 02 - Download Image',
      key: 'download',
    },
    {
      name: 'Step 03 - Checking downloaded Image',
      key: 'check_download',
    },
    {
      name: 'Step 04 - Installation',
      key: 'check_install',
    },
  ]

  happySteps = [
    this.getStatusObject({
      compatibility: 'running',
      active: true,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'running',
      active: true,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'success',
      check_download: 'running',
      active: true,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'success',
      check_download: 'success',
      check_install: 'running',
      active: true,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'success',
      check_download: 'success',
      check_install: 'success',
      active: false,
    }),
  ]

  unhappySteps1 = [
    this.getStatusObject({
      compatibility: 'running',
      active: true,
    }),
    this.getStatusObject({
      compatibility: 'error',
      active: false,
    }),
  ]

  unhappySteps2 = [
    this.getStatusObject({
      compatibility: 'running',
      active: true,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'running',
      active: true,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'error',
      active: false,
    }),
  ]

  unhappySteps3 = [
    this.getStatusObject({
      compatibility: 'running',
      active: true,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'running',
      active: true,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'success',
      check_download: 'running',
      active: false,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'success',
      check_download: 'error',
      active: false,
    }),
  ]

  unhappySteps4 = [
    this.getStatusObject({
      compatibility: 'running',
      active: true,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'running',
      active: true,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'success',
      check_download: 'running',
      active: false,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'success',
      check_download: 'success',
      check_install: 'running',
      active: false,
    }),
    this.getStatusObject({
      compatibility: 'success',
      download: 'success',
      check_download: 'success',
      check_install: 'error',
      active: false,
    }),
  ]

  getStatusObject(config: FirmwareUpdateStatus) {
    const lookup: FirmwareResult = {
      active: true,
      // step 1 (compatibilty check)
      compatibility: {
        running: {
          status_code: 0,
          message: 'Device is checking if update is allowed',
        },
        success: {
          status_code: 1,
          message: 'Update is allowed',
        },
        error: {
          status_code: 2,
          message: 'Update is not allowed',
        },
      },
      // step 2 (download)
      download: {
        running: {
          status_code: 3,
          message: 'Device has started to download the image',
        },
        success: {
          status_code: 4,
          message: 'Image successfully downloaded',
        },
        error: {
          status_code: 5,
          message: 'Error during download',
        },
      },
      // step 3 (download check)
      check_download: {
        running: {
          status_code: 6,
          message: 'Device is checking the downloaded image',
        },
        success: {
          status_code: 7,
          message: 'Downloaded file is okay',
        },
        error: {
          status_code: 8,
          message: 'Error with downloaded file',
        },
      },
      // step 4 (installation)
      check_install: {
        running: {
          status_code: 9,
          message: 'Device started installing the update',
        },
        success: {
          status_code: 10,
          message: 'Installation was successful',
        },
        error: {
          status_code: 11,
          message: 'Error during installation',
        },
      },
    }

    const starter: Record<FirmwareUpdateStatuses | 'active', FirmwareStatus | boolean> = {
      active: false,
      compatibility: {
        status_code: -1,
        message: 'Not started',
      },
      download: {
        status_code: -1,
        message: 'Not started',
      },
      check_download: {
        status_code: -1,
        message: 'Not started',
      },
      check_install: {
        status_code: -1,
        message: 'Not started',
      },
    }

    function isActive(value?: FirmwareUpdateResults | boolean): value is boolean {
      return typeof value === 'boolean'
    }

    Object.keys(config).forEach((key: FirmwareUpdateStatuses) => {
      const value = config[key]
      if (isActive(value)) {
        starter.active = value
      } else {
        if (value) {
          const codeAndKey = (lookup[key] as Record<FirmwareUpdateResults, FirmwareStatus>)[value]
          starter[key] = codeAndKey
        }
      }
    })
    return starter
  }

  runSequence(uuid: string, steps: any[] = this.unhappySteps2) {
    steps.forEach((status, i) => {
      setTimeout(() => {
        this.putFirmwareStatus(uuid, status)
      }, i * 3000)
    })
  }

  // simple but useful, works against first available firmware from first device only
  // take a given device, and use the latest update
  putFirmwareStatus(uuid: string, status: any) {
    const device = this.deviceService.findDeviceByUuid(uuid)
    if (!device?.firmwareUpdates || device.firmwareUpdates.length === 0) {
      return
    }
    const latestUpdate = device.firmwareUpdates[device.firmwareUpdates.length - 1]
    const latestTransactionId = latestUpdate.transaction_id

    const url = `${this.httpBase.backendUrls.firmwareUrl}/update_status`
    this.proficloud.http
      .put(url, {
        transaction_id: latestTransactionId,
        firmware_update_status: status,
      })
      .subscribe((res: any) => {
        latestUpdate.firmware_update_status = res.firmware_update_status
        this.deviceStore
          .updateDeviceDetail(device.endpointId, device.metadata)
          .subscribe({ next: () => {}, error: (err) => this.proficloud.logoutOnUnauthorised(err) })
      })
  }

  /**
   *   aborts a given update
   */
  abortUpdateV2(transactionID: string, endpointID: string) {
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.cancelFirmwareUpdateBusy)

    const url = `${this.httpBase.backendUrls.firmwareUrl}/v2/update/abort/${endpointID}/${transactionID}`
    this.proficloud.http.put(url, {}).subscribe({
      next: (r) => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.cancelFirmwareUpdateSuccess)
        setTimeout(() => this.statusOverlay.resetStatus(), 2000)
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)

        this.statusOverlay.showStatus(this.proficloud.statusOverlays.cancelFirmwareUpdateError)
      },
    })
  }

  // FIRMWARE v2

  requestFirmwareUpdateV2(device: DeviceInfo, firmware: FirmwareV2) {
    const url = `${this.httpBase.backendUrls.firmwareUrl}/v2/update/${device.endpointId}`
    const payload = {
      device_id: device.metadata.uuid,
      endpoint_id: device.endpointId,
      user: this.proficloud.keycloakData.userDetails?.data.email,
      user_id: this.proficloud.keycloakData.userDetails?.data.userId,
      device_type: device.metadata.con_DeviceType,
      firmware_id: firmware.firmware_id,
      firmware_version: firmware.firmware_version,
    }
    return this.proficloud.http.post<FirmwareUpdate>(url, payload)
  }

  getFirmwareUpdateStatusV2(updateTransactionId = '1533e723-b155-4f78-8e57-0831aa822b47') {
    const url = `${this.httpBase.backendUrls.firmwareUrl}/v2/update/${updateTransactionId}`
    this.proficloud.http.get(url).subscribe({
      next: (r) => {
        console.log('update info', r)
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)
      },
    })
  }

  // to be used for checking if something in progress?
  getFirmwareUpdateStatusByEndpointIdV2(endpointId = 'e20bbaa7-8912-4356-878f-e22c164151ee') {
    const url = `${this.httpBase.backendUrls.firmwareUrl}/v2/device/information/${endpointId}`
    this.proficloud.http.get(url).subscribe({
      next: (r) => {
        console.log('update info', r)
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)
      },
    })
  }

  getFirmwareUpdateHistoryByEndpointIdV2(endpointId = 'e20bbaa7-8912-4356-878f-e22c164151ee') {
    const url = `${this.httpBase.backendUrls.firmwareUrl}/v2/device/update/history/${endpointId}`
    return this.proficloud.http.get(url)
  }

  loadAllFirmwareUpdateHistoryV2() {
    ;(this.deviceService.devices || []).forEach((device) => {
      this.getFirmwareUpdateHistoryByEndpointIdV2(device.endpointId).subscribe({
        next: (history: FirmwareUpdate[]) => {
          device.firmwareUpdates = history
        },
        error: (error) => {
          this.proficloud.logoutOnUnauthorised(error)
        },
      })
    })
  }

  loadFirmwareUpdateHistoryV2(device: DeviceInfo) {
    this.getFirmwareUpdateHistoryByEndpointIdV2(device.endpointId).subscribe({
      next: (history: FirmwareUpdate[]) => {
        device.firmwareUpdates = history
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)
      },
    })
  }

  loadAvailableDeviceFirmware(device: DeviceInfo) {
    const availableFirmwareUrl = this.httpBase.backendUrls.firmwareUrl + '/v2/device/update/available/' + device.endpointId
    this.proficloud.http.get<AvailableFirmwareResponse>(availableFirmwareUrl).subscribe({
      next: (response) => {
        device.availableFirmware = response.firmwares
        device.firmwareUpdateAllowed = response.newer_version_available
        if (response.newer_version_available) {
          device.newestFirmware = response.firmwares.find((firmware) => firmware.firmware_id === response.newest_firmware_id)
        } else {
          device.newestFirmware = undefined
        }
      },
      error: (error) => {
        console.warn('single device firmware error', error)
      },
    })
  }
}

export function getFirmwareUpdateAllowState(device: DeviceInfo) {
  if (device.firmwareUpdateAllowed === false) {
    return {
      allowed: false,
      reason: 'No newer firmware versions are available for this device',
    }
  }
  // not if the device is offline
  if (!device.metadata.con_connected) {
    return { allowed: false, reason: 'Firmware update is not possible while device is offline' }
  }
  // not if there are no (new firmwares available)
  if (!device.availableFirmware || device.availableFirmware.length === 0) {
    return { allowed: false, reason: 'No suitable firmware found' }
  }
  // if none of the above conditions match, firmware update is allowed
  return { allowed: true, reason: 'device is online, firmware available, no process running' }
}
